import orderItem from '../../components/order-item/index.vue'
import {mapActions, mapGetters} from "vuex";
export default {
  name: "history-orders",
  components:{
    orderItem
  },
  computed:{
    ...mapGetters({
      historyOrdersList:'profile/historyOrdersList'
    })
  },
  created() {
    this.fetchHistoryOrders()
  },
  methods:{
    ...mapActions({
      fetchHistoryOrders:`profile/GET_HISTORY_ORDERS`
    })
  }
}